import React from "react";
import PatientDetail from "./PatientDetail";
import ProgressReport from "./ProgressReport";
import WellbeingRings from "./WellbeingRings";
import QEEGData from "./QEEGData";
import config from "config";

type Props = {
  sessionList: any[];
  qeegList: any[];
  selectedSessionList: any[];
  isLoading: boolean;
};

const SessionDashboard = ({
  sessionList,
  qeegList,
  selectedSessionList,
  isLoading,
}: Props) => {
  // TODO: Make sure that the number of channel names changes depending on the window width
  // const ref = useRef(null);

  // const [numbers, setNumbers] = useState([]);

  // const [width, setWidth] = useState(0);
  // const [height, setHeight] = useState(0);

  // useLayoutEffect(() => {
  //   if (ref.current != null) {
  //     setWidth(ref.current!['clientWidth']);
  //     setHeight(ref.current!['clientHeight']);
  //   }
  // }, [numbers]);

  // useEffect(() => {
  //   function handleWindowResize() {
  //     if (ref.current != null) {
  //       setWidth(ref.current!['clientWidth']);
  //       setHeight(ref.current!['clientHeight']);
  //     }
  //   }

  //   window.addEventListener('resize', handleWindowResize);

  //   return () => {
  //     window.removeEventListener('resize', handleWindowResize);
  //   };
  // }, []);

  return (
    <div
      style={{
        flexDirection: "row",
        display: "flex",
        marginTop: "15px",
      }}
    >
      <PatientDetail session={sessionList.length > 0 ? sessionList[0] : null} />
      {/* <WellbeingRings /> */}
      {config.qeegEnabled && (
        <QEEGData qeegList={qeegList} isLoading={isLoading} />
      )}
      <ProgressReport sessionList={selectedSessionList} />
    </div>
  );
};

export default SessionDashboard;
