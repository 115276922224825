import moment from "moment";
import { addTimezoneOffset, getDate } from "utils/datetime";

export function formateDataForSessionReport(
  data?: any,
  samplingRateDivisor?: any,
  maxAmplitude?: any
) {
  // samplingRateDivisor: reduces the sampling rate by a constant factor to
  // bring the number of samples down
  // maxAmplitude: cut off the data above the max amplitude
  let newList: any = [];
  let channelList: any = [];

  // aggregate multiple channel list
  let sessionStartTimestamp: number = 0;
  let sessionStartTimestampArray: number[] = [];
  const sessionNames: string[] = [];
  const sessionDates: string[] = [];
  data.map((item: any) => {
    // console.log("Item", item);
    const channels = item?.channels;
    if (channels) {
      if (channels[0].data.length > 0) {
        // Some sessions don't have data. We don't want to show those sessions in the report.
        // Only add sessions that have data

        const channelsWithStartTimestamp = channels.map((c: any) => ({
          ...c,
          sessionStartTimestamp,
        }));
        // channelList.push(...item?.channels);
        channelList.push(...channelsWithStartTimestamp);
        // Add the duration of the last session to the current time to make sure that the data is properly aligned along the x axis
        sessionStartTimestamp +=
          (1 / channels[0].dataSampleRate) * channels[0].dataSamples;
        sessionStartTimestampArray.push(sessionStartTimestamp);
        sessionNames.push(item.experienceName);
        sessionDates.push(item.startDateTime);
        // console.log("Current timestamp", channels[0], sessionStartTimestamp);
      }
    }
  });

  channelList.map((item: any) => {
    const dataList = item?.data;
    const channelStartTimestamp = item?.sessionStartTimestamp;

    if (dataList.length > 0) {
      let value: any[] = [];
      dataList.map((dataObj: any, index: any) => {
        if (dataObj && dataObj.length > 1 && index % samplingRateDivisor == 0) {
          // cut off the max value
          const newTimestamp = channelStartTimestamp + dataObj[0];
          const frequencyValue =
            dataObj[1] < maxAmplitude
              ? [newTimestamp, dataObj[1]]
              : [newTimestamp, maxAmplitude];
          value.push(frequencyValue);
        }
      });
      let newObj: any = {};
      const channelName = item?.channelName;
      var replaced = channelName.replace(/\s/g, ""); // replace extra space
      const channelNameArray = replaced.split("-"); // split string with - character

      if (channelNameArray && channelNameArray.length >= 3) {
        //This is an interesting conditional that filters basically only Supermind sessions
        // TODO: make sure that this also works with other sessions

        const channelName = channelNameArray[2]; // get channel name
        const frequencyBand = channelNameArray[0] + "-" + channelNameArray[1]; // get frequency range
        const isChannelAvailable = newList.filter(
          (o: any) => o.channelName == channelName
        ); // check if channel data is already available in list

        if (isChannelAvailable.length > 0) {
          const findChannelIndex = (e: any) => e.channelName == channelName;

          let frequencyObj: any = {};
          const channelIndex = newList.findIndex(findChannelIndex); //find available channel index

          const o: any = newList[channelIndex];
          const data = o.frequencyData;

          const findFrequencyBandIndex = (e: any) =>
            e.frequencyBand == frequencyBand;

          const frequencyBandIndex = data.findIndex(findFrequencyBandIndex); //find available frequency range
          if (frequencyBandIndex !== -1) {
            const frequencyRange = data[frequencyBandIndex];
            if (frequencyRange) {
              const newAvg = value; // update frequency value
              const oldValue = frequencyRange?.frequencyValue;
              frequencyRange.frequencyValue = oldValue.concat(newAvg);
              //frequencyRangeArray = [...frequencyRangeArray, channelStartTimestamp];
              frequencyRange.sessionData = sessionStartTimestampArray;
              frequencyRange.sessionNames = sessionNames;
              frequencyRange.sessionDates = sessionDates;
              // console.log("Frequency range session data" , frequencyRange.sessionData,)

              data[frequencyBandIndex] = frequencyRange;
              o.frequencyData = data;
            }
          } else {
            // console.log("Frequency band index not available")
            frequencyObj.frequencyBand = frequencyBand; //update frequency data
            frequencyObj.frequencyValue = value; // update frequency value

            frequencyObj.sessionData = sessionStartTimestampArray;
            frequencyObj.sessionNames = sessionNames;
            frequencyObj.sessionDates = sessionDates;
            frequencyObj.channelName = channelName;

            data.push(frequencyObj);
            o.frequencyData = data;
          }
        } else {
          // console.log("Channel not available")
          let frequencyObj: any = {};
          let frequencyData: any = [];
          newObj.channelName = channelName;
          frequencyObj.frequencyBand = frequencyBand;
          frequencyObj.frequencyValue = value;

          frequencyObj.sessionData = sessionStartTimestampArray;
          frequencyObj.sessionNames = sessionNames;
          frequencyObj.sessionDates = sessionDates;
          frequencyObj.channelName = channelName;
          frequencyData.push(frequencyObj);
          newObj.frequencyData = frequencyData;

          newList.push(newObj);
        }
      }
    }
  });

  try {
    //sort by frequency range
    newList.map((item: any) => {
      const fArray = item?.frequencyData;
      const sortList = fArray.sort((a: any, b: any) =>
        parseInt(a.frequencyBand) > parseInt(b.frequencyBand) ? 1 : -1
      );
      item.frequencyData = sortList;
    });
  } catch (e) {}
  const n: any = [];
  newList.map((item: any) => {
    n.push(...item.frequencyData);
  });

  const groupedObject: any = {};
  for (const item of n) {
    const value = item["frequencyBand"];
    if (groupedObject[value] === undefined) {
      groupedObject[value] = [];
    }
    groupedObject[value].push(item);
  }
  var result = Object.entries(groupedObject);
  return result;
}

export const getSessionTimeSimple = (endTime: number) => {
  const minutes = Math.floor(endTime / 60);
  const hours = Math.floor(minutes / 60);
  return (
    String(hours).padStart(2, "0") + ":" + String(minutes).padStart(2, "0")
  );
};

// TODO: Deprecated on 24/05/16
export const getSessionTime = (list: any, index: number) => {
  try {
    if (index > 0) {
      const newList = list.slice(0, index + 1);
      let endTime: any = "";
      newList.map((item: any) => {
        var startTimeNew = moment
          .utc(item?.startDateTime)
          .format("MMM DD hh:mm:ss A");
        var endTimeNew = moment
          .utc(item?.stopDateTime)
          .format("MMM DD hh:mm:ss A");

        var diffNew: any = moment.duration(
          moment(endTimeNew, "MMM DD hh:mm:ss A").diff(
            moment(startTimeNew, "MMM DD hh:mm:ss A")
          )
        );
        var daysN = parseInt(diffNew.asDays());

        var hoursN = parseInt(diffNew.asHours());

        hoursN = hoursN - daysN * 24;

        var minutesN = parseInt(diffNew.asMinutes());
        minutesN = minutesN - (daysN * 24 * 60 + hoursN * 60);

        if (endTime == "") {
          endTime = `${hoursN}:${minutesN}`;
        } else {
          endTime = moment(endTime, "HH:mm")
            .add(hoursN, "hours")
            .add(minutesN, "minutes")
            .format("HH:mm");
        }
      });
      return endTime;
    } else {
      var startTime = moment
        .utc(list[index]?.startDateTime)
        .format("MMM DD hh:mm:ss A");
      var endTime = moment
        .utc(list[index]?.stopDateTime)
        .format("MMM DD hh:mm:ss A");

      var diff: any = moment.duration(
        moment(endTime, "MMM DD hh:mm:ss A").diff(
          moment(startTime, "MMM DD hh:mm:ss A")
        )
      );
      var days = parseInt(diff.asDays());

      var hours = parseInt(diff.asHours());

      let newhours: any = hours - days * 24;

      var minutes = parseInt(diff.asMinutes());
      let newminutes: any = minutes - (days * 24 * 60 + hours * 60);
      if (newminutes <= 9) {
        newminutes = "0" + newminutes;
      }
      if (newhours <= 9) {
        newhours = "0" + newhours;
      }
      return `${newhours}:${newminutes}`;
    }
  } catch (e) {}
};

export const getChartTitle = (item: any) => {
  const chartFrequency = item[1][0]?.frequencyBand || "";
  switch (chartFrequency) {
    case "0-1":
      return "Very Low (0.5-1.5 Hz)";
    case "1-3":
      return "Sleep and Basic Function (1.5-3 Hz)";
    case "3-8":
      return "Low Subconscious (3-8 Hz)";
    case "8-12":
      return "Alpha (8-12 Hz)";
    case "12-15":
      return "Low Beta (12-15 Hz)";
    case "15-23":
      return "Beta (15-23 Hz)";
    case "23-38":
      return "High Beta (23-38 Hz)";
    case "38-48":
      return "Gamma (38-48 Hz)";
    default:
      return "";
  }
};

export const getPlotLines = (item: any, sessionList: any) => {
  // console.log("Get plot lines", item)
  const sessionDataList = item[1][0]?.sessionData || [];
  let plotLines: any = [];
  sessionDataList.map((item1: any, index: number) => {
    // TODO: error is that we use sessionlist[index], even though some items can be empty
    //    if (item1>0){
    const obj = {
      value: item1,
      color: "translate",
      label: {
        // text: sessionList[index] ? getSessionTime(sessionList, index) : "",
        text: getSessionTimeSimple(item1),
        style: {
          color: "gray",
          fontSize: "8px",
        },
        rotation: 0,
        textAlign: "right",
        verticalAlign: "bottom",
        zIndex: 9999,
        y: 15,
      },
      zIndex: 9999,
    };
    // console.log(obj);
    plotLines.push(obj);
  });
  return plotLines;
};

// TODO: Remove: Deprecated on 24/05/16
const getSessionName = (item: any) => {
  try {
    const date = moment(getDate(addTimezoneOffset(item?.sessionTime))).format(
      "YY/MM/DD"
    );
    let name: any = item?.sessionName;
    name = name.replace(/-/g, "<br/>");
    return `${name} <br/> <br/>${date}`;
  } catch (e) {
    return "";
  }
};

const getSessionNameSimple = (name: any, dateString: any) => {
  try {
    name = name.replace(/-/g, "<br/>");
    const date = moment(getDate(addTimezoneOffset(dateString))).format(
      "YY/MM/DD"
    );
    return `${name} <br/> <br/>${date}`;
  } catch (e) {
    return "";
  }
};
export const getPlotBands = (
  item: any,
  sessionList: any,
  selectedSessionList: any
) => {
  const sessionDataList = item[1][0]?.sessionData || [];
  const sessionNameList = item[1][0]?.sessionNames || [];
  const sessionDates = item[1][0]?.sessionDates || [];
  // console.log("Get plot bands session", item);
  let plotBands: any = [];
  sessionDataList.map((item1: any, index: any) => {
    // console.log("Ger Plot bands", item1);
    // TODO: instead of using the index we need to get the index from the item1
    const obj = {
      from: index == 0 ? 0 : sessionDataList[index - 1],
      to: item1,
      color: index % 2 == 0 ? "#EEF7F5" : "rgba(0, 0, 0, 0)",
      label: {
        // text: selectedSessionList[index]
        //   ? getSessionName(selectedSessionList[index])
        //   : "",
        text: sessionNameList[index]
          ? getSessionNameSimple(sessionNameList[index], sessionDates[index])
          : "",
        // verticalAlign: 'bottom',
        // y: -18,
        verticalAlign: "top",
        style: {
          color: "#606060",
          fontSize: "5px",
        },
        zIndex: 9999,
      },
    };
    plotBands.push(obj);
  });
  return plotBands;
};

export const getChartOption = (
  item: any,
  sessionList: any,
  selectedSessionList: any
) => {
  // console.log("Get chart option", item[1]);
  const s = {
    chart: {
      type: "spline",
      scrollablePlotArea: {
        minWidth: 600,
        scrollPositionX: 1,
      },
    },
    title: {
      text: getChartTitle(item),
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      lineWidth: 1,
      labels: {
        enabled: false,
      },
      title: {
        text: "Time (hh:mm)",
        y: 12,
      },
      tickLength: 0,
      minorTickLength: 0,
      plotBands: getPlotBands(item, sessionList, selectedSessionList),
      plotLines: getPlotLines(item, sessionList),
    },
    yAxis: {
      lineWidth: 1,
      gridLineWidth: 0,
      title: {
        text: "uv",
      },
      labels: {
        style: {
          fontSize: "8px",
        },
      },
    },
    plotOptions: {
      spline: {
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 5,
          },
        },
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: item[1][0]?.channelName || "Channel 1",
        color: "#ffeb3b",
        data: item[1][0]?.frequencyValue || [],
      },
      ...(item[1][1]?.frequencyValue
        ? [
            {
              name: item[1][1]?.channelName || "Channel 2",
              color: "#f44336",
              data: item[1][1]?.frequencyValue || [],
            },
          ]
        : []),
      ...(item[1][2]?.frequencyValue
        ? [
            {
              name: item[1][2]?.channelName || "Channel 3",
              color: "#2196f3",
              data: item[1][2]?.frequencyValue || [],
            },
          ]
        : []),
      ...(item[1][3]?.frequencyValue
        ? [
            {
              name: item[1][3]?.channelName || "Channel 4",
              color: "#8bc34a",
              data: item[1][3]?.frequencyValue || [],
            },
          ]
        : []),
      ...(item[1][4]?.frequencyValue
        ? [
            {
              name: item[1][4]?.channelName || "Channel 5",
              color: "#ffc107",
              data: item[1][4]?.frequencyValue || [],
            },
          ]
        : []),
      ...(item[1][4]?.frequencyValue
        ? [
            {
              name: item[1][5]?.channelName || "Channel 6",
              color: "#009688",
              data: item[1][5]?.frequencyValue || [],
            },
          ]
        : []),
      ...(item[1][4]?.frequencyValue
        ? [
            {
              name: item[1][6]?.channelName || "Channel 7",
              color: "#9c27b0",
              data: item[1][6]?.frequencyValue || [],
            },
          ]
        : []),
      ...(item[1][4]?.frequencyValue
        ? [
            {
              name: item[1][7]?.channelName || "Channel 8",
              color: "#9e9e9e",
              data: item[1][7]?.frequencyValue || [],
            },
          ]
        : []),
    ],
    navigation: {
      menuItemStyle: {
        fontSize: "10px",
      },
    },
  };
  return s;
};

//encode svg
const encodeSvgString = (svg: string) => {
  const decoded = unescape(encodeURIComponent(svg));
  const b64String = btoa(decoded);
  const imgSource = `data:image/svg+xml;base64,${b64String}`;
  return imgSource;
};

//jpeg base64
export const svgToDataURI = (
  svgData: string,
  renderWidth: number,
  renderHeight: number,
  index: number
) => {
  const id = Math.random() + "";
  const canvas = document.createElement(`canvas`);
  canvas.setAttribute("id", id);
  canvas.setAttribute("style", "display: none");
  document.body.appendChild(canvas);

  canvas.width = renderWidth;
  canvas.height = renderHeight;

  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("no canvas 2d context");
  }

  const img = document.createElement("img");
  img.setAttribute("src", encodeSvgString(svgData));

  return new Promise((res) => {
    img.onload = function load() {
      ctx.clearRect(0, 0, renderWidth, renderHeight);
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, renderWidth, renderWidth);
      ctx.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        0,
        0,
        canvas.width,
        canvas.height
      );
      const url = canvas.toDataURL("image/jpeg", 1.0);
      const el = document.getElementById(id);
      if (el) el.remove();

      res(url);
    };
  });
};
