import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/ducks/auth/selectors";
import { hasRule } from "../../store/ducks/auth/utils";
import { RULES } from "../../store/ducks/rule/types";
import {
  selectList,
  selectListPagination,
  selectMonthlyStatisticList,
} from "../../store/ducks/sessions/selectors";
import {
  fetchMonthlyStatisticList,
  fetchList,
  delete_,
} from "../../store/ducks/sessions";
import {
  DefaultPaginationParams,
  SessionParams,
} from "../../store/ducks/sessions/types";
import Wrapper from "../../components/Tabs/styled/Wrapper";
import Button from "../../styled/Button";
import Icon from "../../styled/Icon";
import {
  faTrashAlt,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import TableWrapper from "../../styled/Table/TableWrapper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import labels from "../../utils/labels";
import TableBody from "@material-ui/core/TableBody";
import {
  addTimezoneOffset,
  convertMilliSecondsToTimeString,
  getDate,
  getDateTime,
} from "../../utils/datetime";
import SpeedDials from "../../components/SpeedDial";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import GetAppIcon from "@material-ui/icons/GetApp";
import LineGraph from "../../components/LineGraph";
import { Views } from "./index";
import DeleteConfirmationDialog from "../../components/ConfirmationDialog/DeleteConfirmationDialog";
import AuthWrapper from "../../components/MainLayout/styled/AuthWrapper";
import CLink from "../../styled/CSVLink";
import Grid from "../../styled/Grid";
import { sessionHistoryOptions } from "./constants";
import CloseButton from "./styled/CloseButton";
import Checkbox from "components/Checkbox";
import moment from "moment";
import ReactPDF from "@react-pdf/renderer";
import AssessmentImageGenerate from "./components/AssessmentImageGenerate/AssessmentImageGenerate";
import { formateDataForAssessmentImage } from "./components/AssessmentImageGenerate/ImageGenerateUtils";
import queryString from "query-string";
import { api } from "store/ducks/sessions/sagas";
import config from "config";
import GenerateSessionReport from "./components/GenerateSessionReport/GenerateSessionReport";
import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import {
  formateDataForSessionReport,
  getChartOption,
  svgToDataURI,
} from "./components/GenerateSessionReport/SessionReportUtils";
import SessionDashboard from "./components/SessionDashboard";
import { getFrequencyName, Headers, sample } from "./helpers";
import { LoaderContainer } from "./components/SessionDashboard/QEEGData/styles";
import CircularProgress from "components/Loader/styled/CircularProgress";
import Loader from "components/Loader";
import SpinnerWrapper from "components/Loader/styled/SpinnerWrapper";

exporting(Highcharts);
exportData(Highcharts);
interface Props {
  openUsersOverview: () => void;
  openSessionProfile: () => void;
  userId: string | undefined;
  supervisorId: string | undefined;
  changeSessionProfileSessionId: (sessionId: string) => void;
  view: Views;
  sessionProfileParams: SessionParams;
}
const SessionHistory = ({
  userId,
  supervisorId,
  openUsersOverview,
  openSessionProfile,
  changeSessionProfileSessionId,
  sessionProfileParams,
  view,
}: Props) => {
  const dispatch = useAppDispatch();

  // selectedSession is a list of all selected sessions
  const [selectedSession, setSelectedSession] = useState<any>([]);
  const [sessionList, setSessionList] = useState<any>([]);
  const [assessmentData, setAssessmentData] = useState<any>([]);

  const [selectedQEEGSession, setSelectedQEEGSession] = useState<any>([]);
  const [selectedOtherSession, setSelectedOtherSession] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isDataProcessing, setDataProcessing] = useState<boolean>(false);

  const auth = useSelector(selectUser)!;
  const sessions = useSelector(selectList);
  const monthlyStatistic = useSelector(selectMonthlyStatisticList);

  const pagination = useSelector(selectListPagination);
  const csvLink = useRef<any>();
  const showDeleteButton = hasRule(auth, RULES.REST_deleteDataChunk);
  const showShowButton = hasRule(auth, RULES.REST_getDataChunk);

  useEffect(() => {
    if (view === Views.sessionHistory) {
      dispatch(
        fetchMonthlyStatisticList({
          userId,
          supervisorId: supervisorId,
        })
      );

      dispatch(
        fetchList({
          userId,
          pagination: DefaultPaginationParams,
        })
      );
    }
  }, [view]);

  useEffect(() => {
    if (selectedSession && selectedSession.length > 0) {
      getSelectedList();
    } else {
      setSelectedQEEGSession([]);
      setSelectedOtherSession([]);
    }
  }, [selectedSession]);

  const getSelectedList = () => {
    const qeegList: any = [];
    const otherList: any = [];
    selectedSession.map((item: any) => {
      const sessionName: any = item?.sessionName || "";
      let name: any = sessionName?.toLowerCase();
      if (name && name.includes("qeeg")) {
        qeegList.push(item);
      } else {
        otherList.push(item);
      }
    });
    getQEEGDetail(qeegList);
    getSessionListDetail(otherList);
  };

  const getQEEGDetail = (list: any) => {
    if (list.length > 0) {
      setLoading(true);
      Promise.all(list.map((item: any) => fetchSessionDetail(item)))
        .then(async (resp: any) => {
          setLoading(false);
          setSelectedQEEGSession(resp);
        })
        .catch((result: any) => {
          setLoading(false);
          console.log("e==", result);
        });
    }
  };
  const getSessionListDetail = (list: any) => {
    Promise.all(list.map((item: any) => fetchSessionDetail(item)))
      .then(async (resp: any) => {
        setSelectedOtherSession(resp);
      })
      .catch((result: any) => {
        console.log("e==", result);
      });
  };
  const filterList = (list: any) => {
    const groupByCategory = list.reduce((group: any, product: any) => {
      const { sessionTime } = product;
      const date = moment(getDate(addTimezoneOffset(sessionTime))).format(
        "YYYY, MMMM DD"
      );
      group[date] = group[date] ?? [];
      group[date].push(product);
      return group;
    }, {});
    return groupByCategory;
  };
  useEffect(() => {
    if (sessions) {
      const arrayOfObj = Object.entries(
        filterList(JSON.parse(JSON.stringify(sessions)))
      ).map((e) => ({ [e[0]]: e[1] }));

      setSessionList(arrayOfObj);
    }
  }, [sessions]);
  const handleChangePage = (event: any, newPage: number) => {
    dispatch(
      fetchList({
        userId,
        pagination: {
          pageIndex: newPage,
        },
      })
    );
  };

  const handleChangeRowsPerPage = (event: React.SyntheticEvent) => {
    const pageSize = parseInt((event.target as HTMLSelectElement).value, 10);
    dispatch(
      fetchList({
        userId,
        pagination: {
          pageSize,
          pageIndex: 0,
        },
      })
    );
  };

  const showSession = (sessionId: string) => {
    changeSessionProfileSessionId(sessionId);
    openSessionProfile();
  };

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);

  const deleteSession = (sessionId: string) => {
    dispatch(delete_(sessionId));
  };

  const actions = (sessionId: string) => {
    let result = [];
    if (showShowButton) {
      result.push({
        icon: <Icon icon={faExternalLinkAlt} default="true" size="lg" />,
        name: labels.button.show,
        handler: () => showSession(sessionId),
      });
    }
    if (showDeleteButton) {
      result.push({
        icon: <Icon icon={faTrashAlt} default="true" red="true" size="lg" />,
        name: labels.button.delete,
        handler: () => setDeleteConfirmOpen(true),
      });
    }
    return result;
  };

  // generate the filename for neuroassessments and session reports
  const generateFileName = (
    date: moment.Moment,
    name: string,
    suffix: string
  ) => {
    return date.format("YYYY-MM-DD") + "_" + name + "_" + suffix;
  };

  const getTimeLineData = () => {
    if (!monthlyStatistic) {
      return;
    }

    return monthlyStatistic.reduce(
      (out, statistic) => {
        out.data.push(statistic.sessions);
        out.labels.push(getDate(addTimezoneOffset(statistic.sessionTime)));
        return out;
      },
      { labels: [] as string[], data: [] as number[] }
    );
  };
  const fetchSessionDetail = (item: any) => {
    return new Promise(async (resolve, reject) => {
      let params = Object.assign(sessionProfileParams);
      let options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      const token = localStorage.getItem("token");
      if (token) params.token = token;

      const query = queryString.stringify(params);
      let uri = "";
      if (query.length > 0) {
        uri = `${api.single(item?.sessionId)}?${query}`;
      }
      const response = await fetch(`${config.serverUrl}${uri}`, options); //get session detail
      let responseJSON: any | null = null;
      try {
        responseJSON = await response.json();
        // console.log("Fetch session details response", responseJSON)
      } catch (e) {
        reject(e);
      }
      if (response.ok) {
        resolve(responseJSON?.data?.dataChunk);
      } else {
        reject("e");
      }
    });
  };

  const renderUrl = async () => {
    setDataProcessing(true);

    return new Promise(async (resolve, reject) => {
      Promise.all(selectedSession.map((item: any) => fetchSessionDetail(item)))
        .then(async (resp: any) => {
          if (resp && resp.length > 0) {
            let name = "";
            if (sessionList && sessionList.length > 0) {
              const objValue: any = Object.values(sessionList[0]);
              const sessionHistoryList: any = objValue[0] || [];
              const session = sessionHistoryList[0];

              name = session.user
                ? `${session.user.firstname ?? ""} ${
                    session.user.lastname ?? ""
                  }`
                : "";
            }
            const l = JSON.parse(JSON.stringify(selectedSession));
            l.sort((a: any, b: any) => b.sessionTime - a.sessionTime);
            const formattedList = await formateDataForAssessmentImage(resp);
            const date = moment(getDate(addTimezoneOffset(l[0]?.sessionTime)));
            const blob = await ReactPDF.pdf(
              <AssessmentImageGenerate
                data={formattedList}
                userName={name}
                sessionList={selectedSession}
                date={date.format("YYYY, MMMM DD")}
              />
            ).toBlob();
            const url = URL.createObjectURL(blob);
            if (url && url.length > 0) {
              resolve({ generatedUrl: url, name, date });
            }
          }
        })
        .catch((result: any) => {
          console.log("e==", result);
        });
    })
      .then((res) => res)
      .catch((err) => setDataProcessing(false));
  };

  const onCreateAssessmentImage = () => {
    renderUrl()
      .then((generatedObject: any) => {
        const { generatedUrl, name, date } = generatedObject;
        if (generatedUrl) {
          setDataProcessing(false);
          let aTag: any = document.createElement("a");
          aTag.href = generatedUrl;
          aTag.style = "display: none";
          aTag.download = generateFileName(date, name, "Neuro-Assessment.pdf");
          document.body.appendChild(aTag);
          aTag.click();
        } // else -> means something went wrong during pdf generation
        unselectAllSessions();
      })
      .catch((err) => {
        setDataProcessing(false);
      });
  };

  const renderPdfUrl = async () => {
    setDataProcessing(true);
    return new Promise(async (resolve, reject) => {
      const l = JSON.parse(JSON.stringify(selectedSession));
      l.sort((a: any, b: any) => a.sessionTime - b.sessionTime);
      Promise.all(l.map((item: any) => fetchSessionDetail(item)))
        .then(async (resp: any) => {
          if (resp && resp.length > 0) {
            let name = "";
            // let soundsets: string[] = [];
            if (sessionList && sessionList.length > 0) {
              const objValue: any = Object.values(sessionList[0]);
              const sessionHistoryList: any = objValue[0] || [];
              const session = sessionHistoryList[0];
              name = session.user
                ? `${session.user.firstname ?? ""} ${
                    session.user.lastname ?? ""
                  }`
                : "";
            }

            // augment the data
            resp.forEach((element: any) => {
              // add the hasData flag to the element
              element["hasData"] = element["channels"][0]["data"].length > 0;

              // get the soundset from the cloud session parameters
              let soundset = "";
              if (
                element["parameters"]["currentSessionParameters"].length > 0
              ) {
                const soundsetSessionParameter =
                  element["parameters"]["currentSessionParameters"][0][
                    "parameter"
                  ];
                soundset = soundsetSessionParameter === 0 ? "Awe" : "Violin";
              }
              element["soundset"] = soundset;
            });

            const date = moment(getDate(addTimezoneOffset(l[0]?.sessionTime)));

            // Format data for charts
            const formattedList = await formateDataForSessionReport(
              resp,
              10,
              35
            );

            // Generate charts
            Promise.all(
              formattedList.map((item: any, index: number) =>
                renderChart(item, index, resp, l)
              )
            )
              .then(async (base64Data: any) => {
                if (base64Data && base64Data.length > 0) {
                  const blob = await ReactPDF.pdf(
                    <GenerateSessionReport
                      sessionList={resp}
                      userName={name}
                      chartList={base64Data}
                    />
                  ).toBlob();
                  const url = URL.createObjectURL(blob);
                  if (url && url.length > 0) {
                    resolve({ generatedUrl: url, name, date });
                  }
                }
              })
              .catch((result: any) => {
                console.log("e==", result);
              });
          }
        })
        .catch((result: any) => {
          console.log("e==", result);
        });
    })
      .then((res) => res)
      .catch((err) => setDataProcessing(false));
  };

  const onCreateSessionReport = () => {
    renderPdfUrl()
      .then((generatedObject: any) => {
        const { generatedUrl, name, date } = generatedObject;
        if (generatedUrl) {
          setDataProcessing(false);
          let aTag: any = document.createElement("a");
          aTag.href = generatedUrl;
          aTag.style = "display: none";
          aTag.download = generateFileName(date, name, "Session-Report.pdf");
          document.body.appendChild(aTag);
          aTag.click();
        } // else -> means something went wrong during pdf generation
        unselectAllSessions();
      })
      .catch((err) => setDataProcessing(false));
  };
  const renderChart = async (
    item: any,
    index: number,
    list?: any,
    selectedSessionList?: any
  ) => {
    try {
      // @ts-ignore
      const s = Highcharts.chart(
        `chartContainer${index}`,
        getChartOption(item, list, selectedSessionList)
      );
      const svgData = await s.getSVG();
      const res = await svgToDataURI(svgData, 4000, 4000, index);
      return res;
    } catch (e) {
      console.log(e);
    }
  };

  const isSessionSelected = (sessionId: any) => {
    const list = JSON.parse(JSON.stringify(selectedSession));
    if (list) {
      var item = list.find((item: any) => item.sessionId === sessionId);
      if (item) {
        return true;
      }
      return false;
    }
    return false;
  };
  const handleOnSessionClick = (id: any, session: any) => {
    // console.log(session)
    if (isSessionSelected(id)) {
      const list = JSON.parse(JSON.stringify(selectedSession));
      const newList = list.filter((item: any) => item.sessionId !== id);
      setSelectedSession(newList);
    } else {
      const list = JSON.parse(JSON.stringify(selectedSession));
      list.push(session);
      setSelectedSession(list);
    }
  };
  const selectAnySession = () => {
    return selectedSession && selectedSession.length > 0;
  };
  const renderSessionHistoryList = (item: any, index: number) => {
    const objValue: any = Object.values(item);
    const sessionHistoryList: any = objValue[0] || [];
    const getKey = Object.keys(sessionList[index]);
    return (
      <div>
        <p
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          {getKey}
        </p>
        <TableWrapper>
          <Table size="small">
            <TableHead>
              <TableRow>
                {historyOfAllUsers && (
                  <TableCell>{labels.sessions.user}</TableCell>
                )}
                <TableCell>{labels.sessions.provider}</TableCell>
                <TableCell>{labels.sessions.time}</TableCell>
                <TableCell>{labels.sessions.duration}</TableCell>
                <TableCell>{labels.sessions.experience}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {sessionHistoryList &&
                sessionHistoryList.map((session: any, index: number) => (
                  <TableRow key={index}>
                    {historyOfAllUsers && (
                      <TableCell>
                        <Checkbox
                          getValue={() => isSessionSelected(session?.sessionId)}
                          setValue={() => {
                            handleOnSessionClick(session?.sessionId, session);
                          }}
                        />
                        {session.user
                          ? `${session.user.firstname ?? ""} ${
                              session.user.lastname ?? ""
                            }`
                          : ""}
                      </TableCell>
                    )}
                    <TableCell>
                      {!historyOfAllUsers && (
                        <Checkbox
                          getValue={() => isSessionSelected(session?.sessionId)}
                          setValue={() => {
                            handleOnSessionClick(session?.sessionId, session);
                          }}
                        />
                      )}
                      {session.provider
                        ? `${session.provider.firstname ?? ""} ${
                            session.provider.lastname ?? ""
                          }`
                        : ""}
                    </TableCell>
                    <TableCell>{getDateTime(session.sessionTime)}</TableCell>
                    <TableCell>
                      {convertMilliSecondsToTimeString(session.duration)}
                    </TableCell>
                    <TableCell>{session.sessionName}</TableCell>
                    <TableCell align="right">
                      <SpeedDials actions={actions(session.sessionId)} />
                      <DeleteConfirmationDialog
                        open={deleteConfirmOpen}
                        setOpen={setDeleteConfirmOpen}
                        onConfirm={() => deleteSession(session.sessionId)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </div>
    );
  };

  const unselectAllSessions = () => {
    setSelectedSession([]);
  };

  const timeLineData = getTimeLineData();

  const historyOfAllUsers = !userId;

  const renderChartContainer = () => {
    const chartArray: any[] = new Array(8).fill(1);
    return chartArray.map((item: any, index: number) => {
      return (
        <div
          id={`chartContainer${index}`}
          style={{
            display: "none",
          }}
        />
      );
    });
  };

  const getAssessmentData = () => {
    setDataProcessing(true);
    const sampleNew = JSON.parse(JSON.stringify(sample));
    Promise.all(selectedSession.map((item: any) => fetchSessionDetail(item)))
      .then(async (resp: any) => {
        if (resp && resp.length > 0) {
          const formattedList = await formateDataForAssessmentImage(resp);
          // get frequency value
          formattedList.map((o: any) => {
            if (o?.channelName) {
              const fData = o.frequencyData;
              fData.map((i: any) => {
                let band: any = getFrequencyName(i.frequencyBand);
                if (band) {
                  const selectedData = sampleNew[band?.pos];
                  if (selectedData && selectedData[o?.channelName]) {
                    selectedData[o?.channelName] = i?.frequencyValue
                      ? i?.frequencyValue
                      : "-";
                    sampleNew[band?.pos] = selectedData;
                  }
                }
              });
            }
          });
          //calculate total sum
          let sum = Array(25).fill(0);
          try {
            sampleNew.map((item: any) => {
              for (let i = 0; i < Headers.length; i += 1) {
                if (i !== 0) {
                  let value = sum[i];
                  if (
                    item[Headers[i]?.label] &&
                    item[Headers[i]?.label] != "-"
                  ) {
                    value = value + parseFloat(item[Headers[i]?.label]);
                  }
                  sum[i] = value;
                }
              }
            });
          } catch (e) {
            setDataProcessing(false);
          }
          //calculate percentage
          for (let i = 0; i < sum.length; i += 1) {
            if (i !== 0 && sum[i] && sum[i] > 0) {
              sampleNew.map((item: any, index: number) => {
                const fKey = Headers[i]?.key;
                const fPercentageKey = Headers[i + 1]?.key;
                if (item[fKey] && item[fKey] !== "-") {
                  item[fPercentageKey] =
                    ((item[fKey] * 100) / sum[i]).toFixed(2) + "%";
                  item[fKey] = item[fKey]?.toFixed(2);
                }
              });
            }
          }

          setAssessmentData(sampleNew);
          setDataProcessing(false);
          setTimeout(() => {
            if (csvLink && csvLink.current && csvLink.current.link) {
              csvLink?.current?.link?.click();
            }
          }, 1000);
        }
      })
      .catch((result: any) => {
        setDataProcessing(false);
      });
  };

  return (
    <AuthWrapper
      style={{
        position: "relative",
      }}
    >
      <Wrapper>
        <CloseButton
          onClick={() => {
            unselectAllSessions();
            openUsersOverview();
          }}
        />
        {/* {timeLineData && (
          <LineGraph
            label={labels.sessions.last30Sessions}
            labels={timeLineData.labels}
            data={timeLineData.data}
            options={sessionHistoryOptions}
          />
        )} */}
        <SessionDashboard
          qeegList={selectedQEEGSession}
          selectedSessionList={selectedOtherSession}
          sessionList={sessionList}
          isLoading={isLoading}
        />
        {renderChartContainer()}
        <div
          style={{
            flexDirection: "row",
            display: "flex",
          }}
        >
          <h3 style={{ color: "black" }}>Full List</h3>
        </div>
        <TableWrapper>
          <Table size="small">
            {sessionList.map((item: any, index: number) => {
              return renderSessionHistoryList(item, index);
            })}
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  count={pagination.totalElements || 0}
                  rowsPerPage={pagination.pageSize || 0}
                  page={pagination.pageIndex || 0}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableWrapper>
        <br />
        <Grid
          container
          end="true"
          style={{
            height: "auto",
          }}
        >
          {sessions && config.generateAssessmentsEnabled && (
            <Button
              {...(selectAnySession()
                ? { blue: "true", gray: false }
                : { gray: true })}
              type="button"
              disabled={selectAnySession() ? false : true}
              onClick={onCreateAssessmentImage}
            >
              {`Generate Assessment\nImage`}
            </Button>
          )}
          {sessions && config.generateSessionReportsEnabled && (
            <Button
              {...(selectAnySession()
                ? { blue: "true", gray: false }
                : { gray: true })}
              type="button"
              disabled={selectAnySession() ? false : true}
              onClick={onCreateSessionReport}
            >
              Create Session Report
            </Button>
          )}
          {sessions && (
            <Button
              {...(selectAnySession()
                ? { blue: "true", gray: false }
                : { gray: true })}
              onClick={() => getAssessmentData()}
              disabled={selectAnySession() ? false : true}
            >
              <GetAppIcon /> CSV
            </Button>
          )}
          {assessmentData && (
            <CLink
              data={assessmentData}
              target="_blank"
              ref={csvLink}
              filename={
                sessions && sessions[0] && sessions[0].user
                  ? `Assessment data for ${sessions[0].user?.firstname} ${sessions[0].user?.lastname}`
                  : "Assessment Data"
              }
              headers={Headers}
            ></CLink>
          )}
        </Grid>
      </Wrapper>
      {isDataProcessing ? (
        <div
          style={{
            position: "absolute",
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "300px",
            display: "flex",
            zIndex: 999,
          }}
        >
          <CircularProgress size={100} />
        </div>
      ) : null}
    </AuthWrapper>
  );
};

export default SessionHistory;
